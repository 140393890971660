import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

const Home = () => {
  const [users, setUsers] = useState([])

  const fetchUsers = async () => {
    // const res = await fetch('https://jsonplaceholder.typicode.com/users')
    // const data = await res.json()

    const res = await axios.get('https://jsonplaceholder.typicode.com/users')
    setUsers(res.data)
  }
  
  useEffect(() => {
    fetchUsers()
  }, [])

  return (
    <>
      <h1>Home</h1>
      <Link to='/about'>About</Link>
      <br />
      <Link to='/counter'>Counter</Link>
      <ol>
        {users.map((user, index) => (
          <li key={index}>
            {user.name}
          </li>
        ))}
      </ol>
      <button onClick={fetchUsers}>Fetch Users</button>
    </>
  )
}

export default Home
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Counter from './pages/Counter';
import Post from './pages/Post';
// import Class from './components/Class';
// import Functional from './components/Functional';

function App() {
  // const title = 'React'

  return (
    <>
      {/* <Functional title={title} /> */}  
      {/* <Class title={title} /> */}
      <Routes>
        <Route element={<Home />} path='/' />
        <Route element={<About />} path='/about' />
        <Route element={<Counter />} path='/counter' />
        <Route element={<Post />} path='/posts' />
      </Routes>
    </>
  );
}

export default App;

import { Button } from 'reactstrap'
import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <>
      <h1>About</h1>
      <Link to='/'>Back</Link>
      <div className='d-flex justify-content-center mt-3'>
        <Button color="primary">Click Me</Button>
      </div>
    </>
  )
}

export default About
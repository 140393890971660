import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPosts } from '../features/postsSlice'

const Post = () => {
  const posts = useSelector(state => state.posts.posts)
  const status = useSelector(state => state.posts.status)
  const dispatch = useDispatch()

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchPosts())
    }
  }, [dispatch, status])

  return (
    <>
      <h1 className='mb-5'>List Posts</h1>
      {status === 'loading' && <h3>Loading...</h3>}
      {status === 'success' && posts.map((post, index) => (
        <article key={index}>
          <h3>{post.title}</h3>
          <p>{post.body}</p>
        </article>
      ))}
    </>
  )
}

export default Post
import { useSelector, useDispatch } from 'react-redux'
import { increment, decrement } from '../features/counterSlice'

const Counter = () => {
  const counter = useSelector(state => state.counter.value)
  const dispatch = useDispatch()

  return (
    <div>
      <h1>{counter}</h1>
      <button onClick={() => dispatch(decrement())}>-</button>
      <button onClick={() => dispatch(increment())}>+</button>
    </div>
  )
}

export default Counter
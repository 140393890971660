import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../features/counterSlice'
import postsReducer from '../features/postsSlice'

export default configureStore({
  reducer: {
    counter: counterReducer,
    posts: postsReducer
  }
})
